'use client';
import { Url } from 'next/dist/shared/lib/router/router';
import React, { useRef } from 'react';
import Cloud from '@/public/images/Cloud.png';
import Flyer from '@/public/images/iK-Flyer.png';
import FlyerLite from '@/assets/images/Flyer-Lite_no_glare.png';
import Wings from '@/public/images/Wings.png';
import Image from 'next/image';
import { IKButton } from '../shared/material';
import { useMatchMedia } from '@/lib/matchMedia';
import { useRelativeCoordinates } from '@/hooks/useRelativeCoordinates';

interface NotFoundProps {
  title?: string;
  message?: { __html: string };
  buttonText?: string;
  buttonLink?: Url;
}

export default function NotFound({
  title = 'Oops, you’ve gone one tap too far',
  message = {
    __html: `
      <span>
        Go to a <strong>better</strong> place
      </span>`,
  },
  buttonText = 'Go to Homepage',
}: NotFoundProps) {
  const section = useRef(null);

  const { coordinates: mousePosition, handleMouseMove } =
    useRelativeCoordinates(section);

  // To determine if the user is on desktop resolution
  const isDesktopResolution = useMatchMedia('(min-width:500px)', true);

  return (
    <>
      <div>
        <title>404 | Page Not Found</title>
      </div>
      <section
        ref={section}
        onMouseMove={handleMouseMove}
        className='bg-gradient-yellow min-h-[80vh] bg-gradient-to-b py-20'
      >
        <div className='mx-auto py-8 sm:max-w-screen-xl md:px-4 lg:px-6 lg:py-16'>
          <div className='mx-auto max-w-screen-md overflow-hidden text-center xs:overflow-visible'>
            <div className='relative mx-auto flex max-w-[600px] flex-col justify-center'>
              <div className='flex flex-col items-center justify-center'>
                <h1 className='relative top-5 z-[4] flex min-h-[110px] animate-float items-center justify-center font-display text-8xl font-extrabold tracking-tight text-charcoal shadow-black/40 duration-1000 [text-shadow:_5px_10px_10px_var(--tw-shadow-color)] md:top-0 md:mb-4 md:min-h-[128px] md:text-9xl lg:text-9xl'>
                  404
                </h1>

                <div className='absolute top-5 z-[6] flex h-[100px] w-[300px] items-center justify-between md:w-[400px]'>
                  {/* Flyer */}
                  <div className='relative z-[0] flex'>
                    <div className='absolute -left-2 top-2 z-[1] animate-wings'>
                      <Image
                        className='block animate-float-small [animation-delay:_250ms]'
                        src={Wings}
                        alt=''
                        width={65}
                        height={300}
                      />
                    </div>
                    <Image
                      className='z-[2] block animate-float-small [animation-delay:_250ms]'
                      src={Flyer}
                      alt=''
                      width={65}
                      height={300}
                    />
                    <div className='absolute bottom-0 left-[10px] z-[0] h-[20px] w-[40px] animate-float-small rounded-md bg-black/30 blur-[8px] [animation-delay:_250ms]' />
                  </div>

                  {/* Flyer Lite */}
                  <div className='relative flex'>
                    <div className='absolute -right-5 -top-2 scale-x-[-100%] transform'>
                      <div className='z-[1] animate-wings'>
                        <Image
                          className='block animate-float-small [animation-delay:_250ms]'
                          src={Wings}
                          alt=''
                          width={65}
                          height={300}
                        />
                      </div>
                    </div>
                    <Image
                      className='z-[2] block animate-float-small [animation-delay:_250ms]'
                      src={FlyerLite}
                      alt=''
                      width={52}
                      height={300}
                    />
                    <div className='absolute bottom-0 left-[10px] z-[0] h-[20px] w-[40px] animate-float-small rounded-md bg-black/40 blur-[8px] [animation-delay:_250ms]' />
                  </div>
                </div>
              </div>

              <div className='top-0 flex w-full justify-center'>
                {/* Main cloud */}
                <Image
                  style={{
                    left: mousePosition.x
                      ? mousePosition.x / -200 + (isDesktopResolution ? 30 : 0)
                      : isDesktopResolution
                        ? 30
                        : 0,
                    top: mousePosition.x
                      ? mousePosition.x / -200 - (isDesktopResolution ? 40 : 0)
                      : isDesktopResolution
                        ? -40
                        : 0,
                  }}
                  className='absolute -left-[40px] -top-5 z-[0] h-[300px] w-[700px] animate-float-alt sm:h-[unset] sm:w-[unset]'
                  src={Cloud}
                  alt=''
                  width={700}
                  height={300}
                />
                <Image
                  style={{
                    left: mousePosition.x
                      ? mousePosition.x / -200 + (isDesktopResolution ? 80 : 0)
                      : isDesktopResolution
                        ? 80
                        : 0,
                    top: mousePosition.x
                      ? mousePosition.x / -200 - (isDesktopResolution ? 40 : 0)
                      : isDesktopResolution
                        ? -40
                        : 0,
                  }}
                  className='absolute -top-5 left-[40px] z-[0] h-[300px] w-[700px] animate-float-alt sm:h-[unset] sm:w-[unset]'
                  src={Cloud}
                  alt=''
                  width={700}
                  height={300}
                />

                <Image
                  className='absolute left-0 top-0 z-[0] opacity-30'
                  style={{
                    left: mousePosition.x
                      ? mousePosition.x / -200 + (isDesktopResolution ? 0 : 0)
                      : 0,
                    top: mousePosition.x
                      ? mousePosition.x / -200 - (isDesktopResolution ? 64 : 0)
                      : 0,
                  }}
                  src={Cloud}
                  alt=''
                  width={600}
                  height={300}
                />

                <Image
                  className='absolute top-20 z-[5] mx-auto opacity-50 md:top-16'
                  src={Cloud}
                  alt=''
                  width={300}
                  height={100}
                />
                <Image
                  className='absolute -left-5 top-16 z-[5] mx-auto opacity-50 md:top-10'
                  src={Cloud}
                  alt=''
                  width={300}
                  height={100}
                />
                <Image
                  className='absolute top-16 z-[5] mx-auto -mr-48 opacity-60'
                  src={Cloud}
                  alt=''
                  width={300}
                  height={100}
                />
              </div>
              {/* Main cloud */}

              <div className='relative z-[6] mx-auto'>
                <h3 className='mb-4 mt-5 max-w-[400px] px-5 font-display text-3xl font-bold tracking-tight text-gray-900 shadow-black/10 [text-shadow:_3px_3px_3px_var(--tw-shadow-color)] dark:text-white md:mt-0 md:px-0 md:text-5xl'>
                  {title}
                </h3>
              </div>
            </div>

            <div className='mt-10 flex flex-col items-center space-y-10'>
              <p
                className='z-[8] text-3xl font-normal text-charcoal'
                dangerouslySetInnerHTML={message}
              />
              <div className='animate-bounce text-charcoal'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='14'
                  viewBox='0 0 24 14'
                  fill='none'
                >
                  <path
                    d='M12.2411 9.72499L3.53666 1.02056C3.37865 0.842204 3.1819 0.702401 2.96148 0.611874C2.74107 0.521347 2.50285 0.4825 2.2651 0.498313C2.02734 0.514127 1.79636 0.584179 1.58988 0.7031C1.3834 0.82202 1.20689 0.986648 1.0739 1.18436C0.835258 1.51384 0.720807 1.91709 0.750783 2.32281C0.780758 2.72853 0.953229 3.11059 1.2377 3.40142L11.0916 13.172C11.2378 13.3312 11.4154 13.4583 11.6133 13.5453C11.8112 13.6322 12.025 13.6771 12.2411 13.6771C12.4572 13.6771 12.671 13.6322 12.8689 13.5453C13.0667 13.4583 13.2444 13.3312 13.3906 13.172L23.2445 3.40142C23.5289 3.11059 23.7014 2.72853 23.7314 2.32281C23.7614 1.91709 23.6469 1.51384 23.4083 1.18436C23.2753 0.986648 23.0988 0.82202 22.8923 0.7031C22.6858 0.584179 22.4548 0.514127 22.2171 0.498313C21.9793 0.4825 21.7411 0.521347 21.5207 0.611874C21.3003 0.702401 21.1035 0.842204 20.9455 1.02056L12.2411 9.72499Z'
                    fill='currentColor'
                  />
                </svg>
              </div>
              <IKButton
                link={{ url: '/', document: 'home', type: 'internal' }}
                className='flex items-center justify-center gap-2'
                color='charcoal'
              >
                <span>{buttonText}</span>
                <span>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='9'
                    height='14'
                    viewBox='0 0 9 14'
                    fill='none'
                  >
                    <path
                      d='M6.02021 7.00094L0.971877 12.0493C0.868435 12.1409 0.787353 12.255 0.73485 12.3829C0.682346 12.5107 0.659816 12.6489 0.668987 12.7867C0.678159 12.9246 0.718787 13.0586 0.787758 13.1784C0.856729 13.2981 0.952208 13.4005 1.06688 13.4776C1.25797 13.616 1.49184 13.6824 1.72715 13.665C1.96245 13.6476 2.18404 13.5476 2.35271 13.3826L8.01938 7.66761C8.11172 7.58283 8.18544 7.4798 8.23587 7.36504C8.28631 7.25028 8.31235 7.12629 8.31235 7.00094C8.31235 6.87559 8.28631 6.7516 8.23587 6.63684C8.18544 6.52208 8.11172 6.41905 8.01938 6.33427L2.35271 0.619273C2.18403 0.454288 1.96245 0.354261 1.72715 0.336876C1.49184 0.319491 1.25797 0.38587 1.06688 0.524274C0.952208 0.601407 0.856728 0.703775 0.787757 0.82353C0.718787 0.943284 0.678158 1.07724 0.668987 1.21514C0.659816 1.35303 0.682346 1.49119 0.734849 1.61902C0.787352 1.74686 0.868434 1.86097 0.971877 1.95261L6.02021 7.00094Z'
                      fill='currentColor'
                    />
                  </svg>
                </span>
              </IKButton>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
